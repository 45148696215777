import React, { useEffect, useState } from "react"
import labels from "./const"
import useLabels from "../../utils/useLabels"
import Text from "../Text"
import { Button, Col, Container, Row } from "reactstrap"

import * as s from "./style.module.css"
import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import MailerLitePopup from "../MailerLitePopup"

function Idea({ lang, type, isReverse }) {
  const [content, setContent] = useState({})
  const t = useLabels(labels, lang)

  useEffect(() => {
    switch (true) {
      case type === "forWho":
        setContent(t.forWho)
        break
      case type === "aroundH4c":
        setContent(t.aroundH4c)
        break
      case type === "river":
        setContent(t.river)
        break
      case type === "forest":
        setContent(t.forest)
        break
      case type === "cooperation":
        setContent(t.cooperation)
        break
      default:
        break
    }
  }, [t])

  if (content.image2) {

    return (
      <div id={type} className={isReverse ? s.reverseRoot : s.root}>
        <Container className="h-100">
          <Row className={isReverse && "flex-row-reverse"}>
            <Col lg="5" className="position-relative">
              <div className="mr-4">
                {content && (
                  <div className={s.image2}>
                    <IdeaImage alt="" filename={content.image1} />
                    <img alt="" className={s.imageSplash} src={content.splash} />
                    <div className={s.image1}>
                      <IdeaImage alt="" filename={content.image2} />
                    </div>
                  </div>
                )}
              </div>
            </Col>
            <Col lg="1" />
            <Col lg="6">
              <div className="mt-4">
                {content && (
                  <Text title={content.title} text={content.description} />
                )}
              </div>
              {type === "river" && <div className="d-flex justify-content-center align-content-center">

                <a href="https://app.evenea.pl/event/hack4change2025/" target="_blank">
                  <Button
                    color="primary"
                    dangerouslySetInnerHTML={{
                      __html: content.button,
                    }}
                  />
                </a>
                
                {/*<MailerLitePopup buttonText={content.button}/>*/}

              </div>}
            </Col>
          </Row>
        </Container>
        {type === "forWho" && (
          <img
            className={s.forWhoSplash}
            src={require("./images/splash.svg").default}
          />
        )}
      </div>
    )

  } else {
    return (<div></div>)
  }
}

export default Idea

const IdeaImage = props => (
  <StaticQuery query={graphql` query {
    allFile(filter: {extension: {regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/"}, relativeDirectory: {eq: "Idea/images"}}) {
      nodes {
        name
       childImageSharp{
        gatsbyImageData(
             quality: 60
             placeholder: TRACED_SVG
             formats: [AUTO, WEBP, AVIF]
           )
        }
      }
    }
  }`
  } render={data => {

    const image = data.allFile.nodes.find(node => {
      return props.filename.includes(node.name)
    })

    if (!image) {
      return null
    }

    return <GatsbyImage className={props.css} style={{ height: "100%" }}
      alt={"info hack4change"} image={getImage(image.childImageSharp)} />
  }} />
)
